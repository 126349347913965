export function notifySlackQuiz(errorType, errorMessage) {
  fetch(
    "https://hooks.slack.com/services/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",
    {
      method: "POST",
      body: JSON.stringify({
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: errorType,
              emoji: true,
            },
          },
          {
            type: "divider",
          },
          {
            type: "context",
            elements: [
              {
                type: "plain_text",
                text: errorMessage,
                emoji: true,
              },
            ],
          },
        ],
      }),
    }
  );
}
export function notifySlackPet(action, payload) {
  const { IS_STAGING } = useTheme();
  const env = IS_STAGING ? "Staging" : "Production";

  return fetch(
    "https://hooks.slack.com/services/T01MA94A9BM/B072LJR5JD6/YcbRv1Uv2d3eRHHwzrEhyOuR",
    {
      method: "POST",
      body: JSON.stringify({
        blocks: [
          {
            type: "rich_text",
            elements: [
              {
                type: "rich_text_section",
                elements: [
                  {
                    type: "text",
                    text: `ENV: ${env} | Action: ${action}`,
                    style: {
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "divider",
          },
          {
            type: "context",
            elements: [
              {
                type: "plain_text",
                text: JSON.stringify(payload, null, 2),
                emoji: true,
              },
            ],
          },
        ],
      }),
    }
  );
}
